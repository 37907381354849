<template>
  <section>
    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <ag-grid-table
          ref="historico-demanda"
          :configUrl="historicoDemandaConfig"
          :dataUrl="historicoDemandaData"
          :editUrl="historicoDemandaEdit"
          :actions="actions"
        >
        </ag-grid-table>
      </b-col>

      <demanda-ver-detalles-modal ref="demanda-modal"/>

    </b-row>
  </section>
</template>
<script>   
import
{
  BRow,
  BCol,

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import DemandaVerDetallesModal from "./DemandaVerDetallesModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  data()
  {
    return {

      actions: [
        { name: "Eliminar", icon: "Trash2Icon", action: this.actionEliminar },
      ],

      historicoDemandaConfig: useApiServices.historicoDemandaConfig,
      historicoDemandaData: useApiServices.historicoDemandaData,
      historicoDemandaEdit: useApiServices.historicoDemandaEdit,



    };
  },
  components: {
    BRow,
    BCol,
    AgGridTable,
    DemandaVerDetallesModal,
    ToastificationContent
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    actionDetalles(params)
    {
      this.$refs['demanda-modal'].show(params.data)
    },

    actionEliminar(params)
    {


      this.$bvModal.msgBoxConfirm('Confirma si quieres eliminar el registro', {
        title: 'Confirmar',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value =>
        {

          if (value)
          {
            useApiServices.postDemandaDelete(params.data)
              .then((response) =>
              {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Eliminación Exitosa`,
                    icon: "UploadIcon",
                    variant: "success",
                    html: `El registro se ha eliminado.`,
                  },
                });

                this.$refs['historico-demanda'].refreshRows([], false)


              })
              .catch((error) =>
              {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ocurrió un Error`,
                    icon: "UploadIcon",
                    variant: "danger",
                    html: `Ha ocurrido un error</b>.`,
                  },
                });

              });


          }


        })
        .catch(err =>
        {
          // An error occurred
        })


    }    

  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

