<template>
  <b-modal
    :ref="'demanda-modal'"
    ok-only
    ok-title="Close"
    centered
    size="xl"
    title=""
    hide-footer
  >
    <b-card no-body v-if="demanda">
      <b-card-body>
        <dl class="row">
          <dt class="col-sm-3">Fecha de Inicio</dt>
          <dd class="col-sm-9">{{ demanda.meta_data.start_date }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-3">Fecha de Termino</dt>
          <dd class="col-sm-9">{{ demanda.meta_data.end_date }}</dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Office</dt>
          <dd class="col-sm-9">{{ demanda.meta_data.office }}</dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Origen</dt>
          <dd class="col-sm-9">{{ demanda.meta_data.origen }}</dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Program Type</dt>
          <dd class="col-sm-9">{{ demanda.meta_data.program_type }}</dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Delivery Type</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.delivery_type }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Status Program</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.status_program }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Type of Demand</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.type_of_demand }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">RMP Weighted usd/kg</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.rmp_weighted_usd_kg }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Total Contract wfe kg</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.total_contract_wfe_kg }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Total Shipping Cost USD</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.total_shipping_cost_usd }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">FOB Chile Weighted usd/kg</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.fob_chile_weighted_usd_kg }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Total Contract Overweight kg</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.total_contract_overweight_kg }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-3">Total Contract Value USD FOB Chile</dt>
          <dd class="col-sm-9">
            {{ demanda.meta_data.total_contract_value_usd_fob_chile }}
          </dd>
        </dl>

        <!--
            <b-row>
              <b-col>
                <b-card-text>
                  <pre>{{ JSON.stringify(cellValue.data, null, 2) }}</pre>
                </b-card-text>
              </b-col>
            </b-row>
            -->
      </b-card-body>
    </b-card>
  </b-modal>
</template>
  <script>   



import
{
  BRow,
  BCol,

} from "bootstrap-vue";


export default {
  data()
  {
    return {

      demanda: null,


    };
  },
  components: {
    BRow,
    BCol,
  },

  directives: {
  },

  mounted()
  {

  },

  methods: {

    show(demanda)
    {

      this.demanda = demanda

      this.$refs['demanda-modal'].show()



    }


  },
};
  </script>
  
  <style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
  
  